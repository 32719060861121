import { patchAlbumApi } from '~/app/lib/songwhipApi/albums';

import dispatchFetchItemSuccess from '../../lib/dispatchFetchItemSuccess';
import { updateDashboardItem } from '../../dashboard/actions';
import { Dispatch } from '../../types';

import { fetchAlbumError, fetchAlbumStart } from './fetchAlbum';

export const patchAlbum =
  (params: Parameters<typeof patchAlbumApi>[0]) =>
  async (dispatch: Dispatch) => {
    const { albumId } = params;

    try {
      dispatch(fetchAlbumStart(albumId));
      const album = await patchAlbumApi(params);
      dispatchFetchItemSuccess(dispatch, album);

      // Update album item in dashboard redux state to reflect the changes
      dispatch(updateDashboardItem(album));
    } catch (error) {
      dispatch(fetchAlbumError(albumId, error));
      throw error;
    }
  };
